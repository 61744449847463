import { RefresherEventDetail } from '@ionic/core'
import {
  IonAlert,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonProgressBar,
  IonSpinner,
  IonTextarea,
  IonToast,
  isPlatform,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react'
import FileSaver from 'file-saver'
import { caretUp, cloudUpload, logInOutline, refresh } from 'ionicons/icons'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import TicketComment from '../components/TicketComment'
import { inFunctionList } from '../helpers/base'
import {
  dataURLtoBlob,
  getDownloadUrl,
  getIFileProgressKey,
  getProgressNumber,
  hashCode,
  isVersionMedia,
  openDocument,
} from '../helpers/file'
import FileViewerModal from '../modals/FileViewerModal'
import PhotoViewerModal from '../modals/PhotoViewerModal'
import UploadModal from '../modals/UploadModal'
import { id } from '../models/base'
import { ETicketCommentUserType, ITicketComment } from '../models/comment'
import {
  DEFAULT_TEXTAREA_ROWS,
  DELAY_SHORT,
  DELAY_TOAST,
} from '../models/constants'
import { IFile, ISuiquiFile } from '../models/suiquiFile'
import { ETicketFunction, ETicketUserType, ITicket } from '../models/ticket'
import {
  addComment,
  addPublicComment,
  fetchCommentList,
  fetchPublicCommentList,
  removeComment,
  uploadComment,
  uploadCommentFilesCancelAll,
  uploadPublicComment,
} from '../store/epics/comment'
import { downloadFile } from '../store/epics/downloadFile'
import { ReactRouterAction } from '../store/epics/types'
import './ListTicketComment.scss'

interface ListTicketCommentProps
  extends RouteComponentProps<{ key: string }>,
    ReactRouterAction {
  userType: ETicketUserType
  pageWidth?: number
  pageHeight?: number
  isAllowNewComment: boolean
}

const ListTicketComment = ({
  match,
  userType,
  isAllowNewComment,
}: ListTicketCommentProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const contentRef = useRef(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [showEmpty, setShowEmpty] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [currentTicketUserType, setCurrentTicketUserType] =
    useState<ETicketUserType>()
  const [textareaValue, setTextareaValue] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showTicketCommentAddToast, setShowTicketCommentAddToast] =
    useState(false)
  const [showTicketCommentUploadToast, setShowTicketCommentUploadToast] =
    useState(false)
  const [showTicketCommentRemoveToast, setShowTicketCommentRemoveToast] =
    useState(false)
  const [showTicketCommentRemoveAlert, setShowTicketCommentRemoveAlert] =
    useState(false)
  const [shouldRefresh, setShouldRefresh] = useState(false)
  const [files, setFiles] = useState<File[]>()
  const [filenames, setFilenames] = useState<string[]>()
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [showPhotoViewerModal, setShowPhotoViewerModal] = useState(false)
  const [showFileViewerModal, setShowFileViewerModal] = useState(false)
  const [currentItem, setCurrentItem] = useState<any>()
  const [currentItems, setCurrentItems] = useState<any[]>()
  const [currentIndex, setCurrentIndex] = useState<number>()

  const ticket = useSelector((state: any) => state.ticket)
  const comment = useSelector((state: any) => state.comment)
  const authDuck = useSelector((state: any) => state.authDuck)

  useIonViewWillEnter(() => {
    if (showUploadModal) return
    if (showPhotoViewerModal) return
    if (showFileViewerModal) return
    if (isOpen) return

    setIsOpen(true)
    setIsPageLoading(true)
    setIsProcessing(true)
    setCurrentTicketUserType(userType)
  }, [
    setIsProcessing,
    setIsPageLoading,
    setCurrentTicketUserType,
    userType,
    showUploadModal,
    showPhotoViewerModal,
    showFileViewerModal,
    isOpen,
    setIsOpen,
  ])

  useIonViewWillLeave(() => {
    setIsOpen(false)
    dispatch(uploadCommentFilesCancelAll({}))
  }, [dispatch, setIsOpen])

  useEffect(() => {
    if (!comment?.comments) return
    if (comment.isDone && comment?.comments?.length >= 0) {
      setIsProcessing(false)
      setShowEmpty(true)
      setIsPageLoading(false)
      setIsLoaded(!comment?.error)
    }
  }, [setShowEmpty, setIsPageLoading, setIsProcessing, setIsLoaded, comment])

  const getCommentUserType = (userType?: ETicketUserType) => {
    if (userType === ETicketUserType.service_ppl) {
      return ETicketCommentUserType.serv
    } else if (userType === ETicketUserType.user) {
      return ETicketCommentUserType.user
    } else {
      return
    }
  }

  const doRefresh = useCallback(
    (event?: CustomEvent<RefresherEventDetail>) => {
      setIsProcessing(true)

      dispatch(uploadCommentFilesCancelAll({}))

      if (currentTicketUserType) {
        dispatch(fetchPublicCommentList.cancel())

        dispatch(
          fetchPublicCommentList.request({
            key: match.params.key,
            event,
          })
        )
      } else {
        dispatch(fetchCommentList.cancel())

        dispatch(
          fetchCommentList.request({
            key: match.params.key,
            event,
          })
        )
      }
    },
    [dispatch, currentTicketUserType, match.params.key]
  )

  const handleAddComment = () => {
    const text = textareaValue
    setIsSubmitting(true)

    if (currentTicketUserType) {
      if (files?.length) {
        const hashFunc = (file: IFile, index: number) =>
          hashCode(`${file.name}-${index}`)

        dispatch(
          uploadPublicComment.request({
            commentUserType: getCommentUserType(currentTicketUserType),
            key: match.params.key,
            text,
            files,
            filenames,
            hashFunc,
          })
        )
      } else {
        dispatch(
          addPublicComment.request({
            commentUserType: getCommentUserType(currentTicketUserType),
            key: match.params.key,
            text,
          })
        )
      }
    } else {
      if (files?.length) {
        const hashFunc = (file: IFile, index: number) =>
          hashCode(`${file.name}-${index}`)

        dispatch(
          uploadComment.request({
            key: match.params.key,
            text,
            files,
            filenames,
            hashFunc,
          })
        )
      } else {
        dispatch(
          addComment.request({
            key: match.params.key,
            text,
          })
        )
      }
    }
  }

  const loadData = (event?: any) => {
    setIsProcessing(true)
    if (currentTicketUserType) {
      dispatch(
        fetchPublicCommentList.request({
          key: match.params.key,
          cursor: comment.cursor,
        })
      )
    } else {
      dispatch(
        fetchCommentList.request({
          key: match.params.key,
          cursor: comment.cursor,
        })
      )
    }
  }

  const scrollToTop = () => {
    // @ts-ignore
    contentRef.current!.scrollToTop(DELAY_SHORT)
  }

  const getTicketName = (item: ITicket) => {
    if (isPlatform('mobile') && !isPlatform('ipad')) {
      return item?.short_display_name || item?.name
    }
    return item?.display_name || item?.name
  }

  const getTicketCommentName = (comment?: ITicketComment) => {
    return `${t('Comment')}`
  }

  const removeTicketCommentAction = ({ key }: { key: id }) => {
    if (!key) return
    setIsSubmitting(true)
    dispatch(
      removeComment.request({
        key,
      })
    )
  }

  useEffect(() => {
    if (!comment.added) return
    setTextareaValue(undefined)
    setShowTicketCommentAddToast(true)
  }, [comment.added, setShowTicketCommentAddToast, setTextareaValue])

  useEffect(() => {
    if (!comment.uploaded) return
    setTextareaValue(undefined)
    setFiles(undefined)
    setFilenames(undefined)
    setShowTicketCommentUploadToast(true)
  }, [
    comment.uploaded,
    setShowTicketCommentUploadToast,
    setTextareaValue,
    setFiles,
    setFilenames,
  ])

  useEffect(() => {
    if (!comment.removed) return
    setShowTicketCommentRemoveToast(true)
  }, [comment.removed, setShowTicketCommentRemoveToast])

  useEffect(() => {
    if (!shouldRefresh) return
    doRefresh()
    setShouldRefresh(false)
  }, [doRefresh, setShouldRefresh, shouldRefresh])

  const dismissUploadModal = (values: any) => {
    setShowUploadModal(false)
    setFiles(undefined)
    setFilenames(undefined)

    if (values?.files) {
      setFiles(values.files)
    }
    if (values?.filenames) {
      setFilenames(values.filenames)
    }
  }

  const handleUploadClick = (event: any) => {
    event.preventDefault()
    setShowUploadModal(true)
  }

  const getProgressNumberCallback = useCallback((pFiles) => {
    if (!pFiles) return 0
    let total = 0
    const keys = Object.keys(pFiles)
    keys.forEach((key) => {
      const n = getProgressNumber(pFiles[key])
      total = total + n
    })
    return keys.length ? total / keys.length : 0
  }, [])

  const dismissPhotoViewerModal = (values: any) => {
    setCurrentItem(null)
    setShowPhotoViewerModal(false)
  }

  const dismissFileViewerModal = (values: any) => {
    setCurrentItem(null)
    setShowFileViewerModal(false)
  }

  const openPhotoViewerModal = (
    event: React.MouseEvent<any, MouseEvent>,
    index: number,
    items: ISuiquiFile[]
  ) => {
    setCurrentItem(items[index])
    setCurrentItems(items)
    setCurrentIndex(index)

    setShowPhotoViewerModal(true)
  }

  const execDownload = (
    event: React.MouseEvent<any, MouseEvent>,
    file: IFile,
    filename: string
  ) => {
    event.preventDefault()

    const url = getDownloadUrl(file)

    if (!url) return

    if (url.startsWith('data:')) {
      FileSaver.saveAs(dataURLtoBlob(url), filename)
    } else {
      const hash = getIFileProgressKey(file)

      dispatch(
        downloadFile.request({
          key: file.key,
          name: filename || '',
          url,
          hash,
          file,
        })
      )
    }
  }

  const execOpen = (
    event: React.MouseEvent<any, MouseEvent>,
    file: IFile,
    filename: string
  ) => {
    if (isVersionMedia(file)) {
      setCurrentItem({
        ...file,
        name: filename || file.name,
        url: `${file.url}`,
      })
      setShowFileViewerModal(true)
    } else {
      isPlatform('mobile')
        ? openDocument(file, false, t('IOS_INAPP_FILE_OPEN_ERROR_MESSAGE'))
        : execDownload(event, file, filename)
    }
  }

  const getFileFromDevice = (
    event: React.MouseEvent<
      HTMLIonButtonElement | HTMLIonItemElement | HTMLIonTextElement,
      MouseEvent
    >
  ) => {
    event.persist()

    // @ts-ignore
    fileInputRef.current.click()
  }

  const getFileName = (file: File & { rename?: string }) => {
    if (file.rename) return file.rename
    if (file.name === 'image.jpg') {
      file.rename = `${Date.now()}.jpg`
      return file.rename
    }

    return file.name
  }

  const processWebUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    let currentFiles = Array.from(event.target.files || [])
    event.target.value = ''
    if (!currentFiles.length) return

    const pendingFiles = [...Array.from(files || []), ...currentFiles]
    setFiles(pendingFiles)
    setFilenames(
      (pendingFiles || []).map((file: File) => {
        return getFileName(file)
      })
    )
  }

  return (
    <IonPage className="page-list-ticket-comments">
      <IonContent
        className="ion-padding bg"
        ref={contentRef}
        scrollEvents={true}
      >
        {isPageLoading ? (
          <div className="ion-text-center centered">
            <IonSpinner color="primary" name="crescent"></IonSpinner>
          </div>
        ) : (
          <>
            {isLoaded && isAllowNewComment && (
              <form>
                <IonItemDivider
                  color="light"
                  className="pb-5 new-comment-actions"
                >
                  <IonButtons slot="start">
                    {currentTicketUserType ? (
                      // 「切換至登入的使用者」按鈕
                      <IonButton
                        color="light"
                        fill="solid"
                        className="ticket anonymous"
                        hidden={!authDuck?.isUserLoaded}
                        onClick={() => {
                          setCurrentTicketUserType(undefined)
                          setShouldRefresh(true)
                        }}
                      >
                        <IonIcon slot="start" icon={logInOutline} />
                        {t('Switch Logged')}
                      </IonButton>
                    ) : (
                      // 切換至已登入的使用者後，顯示使用者名稱
                      inFunctionList(
                        ETicketFunction.ticket_read,
                        ticket?.function_list
                      ) && (
                        <IonButton
                          color="primary"
                          fill="clear"
                          data-title="username"
                          className="ticket user immutable"
                        >
                          <span>{authDuck.currentUser?.displayname}</span>
                        </IonButton>
                      )
                    )}
                  </IonButtons>

                  {/* 「上傳檔案」按鈕 */}
                  <IonButtons slot="end">
                    <input
                      type="file"
                      ref={fileInputRef}
                      multiple
                      style={{
                        position: 'absolute',
                        visibility: 'hidden',
                        height: '0px',
                      }}
                      name="files[]"
                      onChange={processWebUpload}
                    />
                    <IonButton
                      disabled={
                        isSubmitting ||
                        isProcessing ||
                        ticket.error ||
                        !ticket.key ||
                        !inFunctionList(
                          ETicketFunction.ticket_comment,
                          ticket?.function_list
                        )
                      }
                      fill="clear"
                      onClick={
                        isPlatform('desktop') || (files && files?.length > 0)
                          ? handleUploadClick
                          : (event) => getFileFromDevice(event)
                      }
                    >
                      <IonIcon slot="start" icon={cloudUpload} />
                      <IonLabel>{t('Attach Files')}</IonLabel>
                    </IonButton>

                    {files?.length && (
                      <IonBadge
                        color="warning"
                        slot="end"
                        className="file-count"
                      >
                        {files?.length || 0}
                      </IonBadge>
                    )}
                  </IonButtons>
                </IonItemDivider>

                {/* 留言輸入區 */}
                <IonItem>
                  <IonTextarea
                    placeholder={t('Input comment here.')}
                    rows={DEFAULT_TEXTAREA_ROWS}
                    onIonChange={(e) => setTextareaValue(e.detail.value!)}
                    value={textareaValue}
                    disabled={
                      isSubmitting ||
                      isProcessing ||
                      ticket.error ||
                      !ticket.key ||
                      !inFunctionList(
                        ETicketFunction.ticket_comment,
                        ticket?.function_list
                      )
                    }
                  ></IonTextarea>
                </IonItem>

                <IonProgressBar
                  type={comment.doneCount > 0 ? 'determinate' : 'determinate'}
                  color={
                    comment?.error
                      ? 'danger'
                      : comment.doneCount < (files?.length || 0)
                      ? 'warning'
                      : showTicketCommentUploadToast
                      ? 'success'
                      : 'medium'
                  }
                  value={getProgressNumberCallback(comment?.pFiles)}
                ></IonProgressBar>

                {/* 「送出」按鈕 */}
                <IonButton
                  color="primary"
                  size="default"
                  shape="round"
                  expand="full"
                  className="ion-margin-top"
                  disabled={
                    (!textareaValue && !files?.length) ||
                    isSubmitting ||
                    isProcessing
                  }
                  onClick={() => {
                    handleAddComment()
                  }}
                >
                  {t('Submit')}
                </IonButton>
              </form>
            )}

            {isLoaded && (
              <>
                <IonItemDivider color="light"></IonItemDivider>

                {/* 「重新整理」按鈕 */}
                <IonItemDivider color="light" className="pb-5">
                  <IonButtons slot="end">
                    {/* 工單留言數量 */}
                    {!!comment.comments?.length && (
                      <IonBadge color="success" className="comment-count">
                        {comment.comments.length > 9
                          ? '9+'
                          : comment.comments.length}
                      </IonBadge>
                    )}

                    <IonButton
                      disabled={isProcessing}
                      fill="solid"
                      color="light"
                      onClick={() => setShouldRefresh(true)}
                    >
                      <IonIcon slot="start" icon={refresh}></IonIcon>
                      <IonLabel>{t('Refresh')}</IonLabel>
                    </IonButton>
                  </IonButtons>
                </IonItemDivider>

                {/* 留言列表 */}
                <IonList lines="inset">
                  {isProcessing && (
                    <IonItem lines="none">
                      <IonSpinner color="primary" name="dots"></IonSpinner>
                    </IonItem>
                  )}

                  {/* 沒有留言時顯示「尚無留言」 */}
                  {comment?.isDone &&
                    !comment?.error &&
                    !comment.comments?.length &&
                    showEmpty && (
                      <IonCard>
                        <IonCardHeader className="ion-text-center">
                          <IonCardTitle>
                            <IonLabel color="medium">
                              {t('No Comments')}
                            </IonLabel>
                          </IonCardTitle>
                        </IonCardHeader>
                      </IonCard>
                    )}

                  {comment.comments?.map(
                    (item: ITicketComment, index: number) => {
                      return (
                        <div key={index} className="comment-item">
                          <TicketComment
                            item={item}
                            index={index}
                            userType={getCommentUserType(currentTicketUserType)}
                            disabled={isSubmitting || isProcessing}
                            removeFn={(item: ITicketComment) => {
                              setCurrentItem(item)
                              setShowTicketCommentRemoveAlert(true)
                            }}
                            execDownload={execDownload}
                            execOpen={execOpen}
                            openPhotoViewerModal={openPhotoViewerModal}
                          />
                        </div>
                      )
                    }
                  )}
                </IonList>
              </>
            )}
          </>
        )}

        <div
          className="ion-text-center"
          hidden={isProcessing || !!comment.cursor}
        >
          <IonButton
            color="warning"
            fill="clear"
            size="large"
            slot="icon-only"
            onClick={scrollToTop}
          >
            <IonIcon icon={caretUp} />
          </IonButton>
        </div>

        <IonInfiniteScroll
          threshold="50%"
          onIonInfinite={loadData}
          disabled={!comment?.cursor}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>

        <IonModal isOpen={showUploadModal} backdropDismiss={false}>
          {showUploadModal && (
            <UploadModal
              isOpen={showUploadModal}
              files={files}
              isPending={true}
              isSaveMode={true}
              dismiss={dismissUploadModal}
              state={comment}
            />
          )}
        </IonModal>

        <IonModal
          isOpen={showPhotoViewerModal}
          showBackdrop={false}
          backdropDismiss={false}
          cssClass="modal-fullscreen"
        >
          {showPhotoViewerModal && (
            <PhotoViewerModal
              isOpen={showPhotoViewerModal}
              item={currentItem}
              items={currentItems}
              index={currentIndex}
              dismiss={dismissPhotoViewerModal}
            />
          )}
        </IonModal>

        <IonModal
          isOpen={showFileViewerModal}
          showBackdrop={false}
          backdropDismiss={false}
          cssClass="modal-fullscreen"
        >
          {showFileViewerModal && (
            <FileViewerModal
              isOpen={showFileViewerModal}
              item={currentItem}
              dismiss={dismissFileViewerModal}
            />
          )}
        </IonModal>

        <IonAlert
          keyboardClose={true}
          isOpen={showTicketCommentRemoveAlert}
          onDidDismiss={() => setShowTicketCommentRemoveAlert(false)}
          header={t('Notice')}
          message={t('Click OK to remove this item.')}
          buttons={[
            {
              text: t('Cancel'),
              role: 'cancel',
              cssClass: 'cancel',
              handler: () => {},
            },
            {
              text: t('OK'),
              cssClass: 'ok',
              handler: (params) => {
                removeTicketCommentAction({ ...currentItem })
              },
            },
          ]}
        />

        <IonToast
          isOpen={showTicketCommentAddToast}
          onDidPresent={() => {
            setShouldRefresh(true)
          }}
          onDidDismiss={() => {
            setShowTicketCommentAddToast(false)
            setIsSubmitting(false)
          }}
          message={t('“{{target}}” has been commented successfully.', {
            target: getTicketName(ticket),
          })}
          duration={DELAY_TOAST}
        />

        <IonToast
          isOpen={showTicketCommentUploadToast}
          onDidPresent={() => {
            setShouldRefresh(true)
          }}
          onDidDismiss={() => {
            setShowTicketCommentUploadToast(false)
            setIsSubmitting(false)
          }}
          message={t('“{{target}}” has been uploaded successfully.', {
            target: getTicketName(ticket),
          })}
          duration={DELAY_TOAST}
        />

        <IonToast
          isOpen={showTicketCommentRemoveToast}
          onDidPresent={() => {
            setShouldRefresh(true)
          }}
          onDidDismiss={() => {
            setShowTicketCommentRemoveToast(false)
            setIsSubmitting(false)
          }}
          message={t('“{{target}}” has been removed successfully.', {
            target: getTicketCommentName(currentItem),
          })}
          duration={DELAY_TOAST}
        />
      </IonContent>
    </IonPage>
  )
}

export default ListTicketComment
