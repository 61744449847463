import { date, id, url } from './base'
import { IUser } from './user'

export enum ESuiquiFileType {
  directory = 'directory',
  file = 'file',
  version_file = 'version_file',
}

export interface IFile extends Partial<ISuiquiVersionFile> {}

export interface ISuiquiVersionFile {
  key: id
  is_image: boolean
  url: url
  content_type: string
  name: string
  type: ESuiquiFileType.version_file | string
  version: number
  user: IUser
  blob_info: {
    content_type: string
    creation: string
    filename: string
    size: number
  }
  memo?: string
  modified_datetime: date
  create_datetime: date
}

export enum ESuiquiFileFunction {
  dir_read = 'dir_read',
  dir_write = 'dir_write',
  dir_delete = 'dir_delete',
  dir_update_permission = 'dir_update_permission',
  file_read = 'file_read',
  file_add_new_version = 'file_add_new_version',
  file_delete = 'file_delete',
  file_update_permission = 'file_update_permission',
}

export interface ISuiquiFile {
  key: id
  parent_key?: id
  is_image: boolean
  url: url
  content_type: string
  name: string
  type: ESuiquiFileType
  version_file: ISuiquiVersionFile
  memo?: string
  function_list: ESuiquiFileFunction[]
  user?: IUser
  modified_datetime: date
  create_datetime: date
  creator?: IUser
  blob_info?: {
    content_type: string
    creation: string
    filename: string
    size: number
  }
}

export const getFileVersion = (file: ISuiquiVersionFile) => {
  return file.version > 0 ? `V${file.version}` : undefined
}

export interface IProgressFile {
  hash: number
  isDone: boolean
  isCancel: boolean
  isPending: boolean
  isDownloading: boolean
  percentage: number
}
